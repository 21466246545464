import React from 'react';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
  let navigate = useNavigate();
  return (
    <span onClick={() => navigate(-1)} className=" flex  items-center mt-8  cursor-pointer ">
      <FaArrowLeftLong className=" mr-4" /> <span className=" text-sm font-semibold ">Back</span>
    </span>
  );
};

export default BackButton;
