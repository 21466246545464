import Button from 'components/shared/Button';
import React, { useEffect, useState } from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { TbPlus } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import AddRecordModal from './AddRecordModal';
import { useAppSelector } from 'store/hooks';
import BookKeepingTable from './BookKeepingTable';
import { Record } from './types';
import useBookKeeping from 'Hooks/useBookKeeping';

const BookKeeping = () => {
  let navigate = useNavigate();
  const { getRecords } = useBookKeeping();
  let { data, loading } = useAppSelector((m) => m.bookKeeping);

  useEffect(() => {
    if (data.length < 1) {
      getRecords();
    }
  }, []);
  const [showModal, setShowModal] = useState(false);
  const [editing, setEditing] = useState<Record | undefined>(undefined);

  const handleEditing = (x: Record) => {
    setEditing(x);
    setShowModal(true);
  };

  return (
    <div className=" ">
      {showModal && (
        <AddRecordModal
          isEditing={Boolean(editing)}
          value={editing}
          closer={() => {
            setShowModal(false);
            setEditing(undefined);
          }}
        />
      )}
      <div className=" flex items-center">
        <span
          onClick={() => navigate(-1)}
          className="text-borange mr-2 flex cursor-pointer  items-center">
          <BsArrowLeft className=" mr-2" /> Back
        </span>
      </div>

      <div className="">
        <div className="flex items-center justify-between">
          <p>Book Keeping</p>
          <Button
            onClick={() => setShowModal(true)}
            text="Add Record"
            LeftIcon={<TbPlus color="white" />}
          />
        </div>
      </div>

      <BookKeepingTable data={data} loading={loading} setEditing={handleEditing} />
    </div>
  );
};

export default BookKeeping;
