import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { FaArrowLeftLong, FaPlus } from 'react-icons/fa6';
import { TbSearch } from 'react-icons/tb';
import Button from 'components/shared/Button';

import { postForm } from 'apis/postForm';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { loadCategories } from 'store/slices/tutorials';
import GhostBids, { GhostCard } from 'components/projects/bids/projectowner/bid/GhostBids';
import NoContent from 'components/projects/photos/NoContent';

const useTutorials = (wait: boolean = false) => {
  const { data, loading } = useAppSelector((m) => m.tutorials);
  const dispatch = useAppDispatch();
  const getTutorials = async () => {
    dispatch(loadCategories({ loading: true }));
    let { e, response } = await postForm(
      'get',
      'learning-materials/category/list',
      undefined,
      'iam'
    );

    if (response) {
      dispatch(loadCategories({ data: response.data.data, loading: false }));
    } else {
      dispatch(loadCategories({ loading: false }));
    }
  };
  useEffect(() => {
    if (wait === false) {
      getTutorials();
    }
  }, []);

  return { getTutorials };
};

export default useTutorials;
