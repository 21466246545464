import { _getFileUrl, convertToSentence } from 'components/shared/utils';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CategoryGallery, CategoryImage } from '../types';
import { FaEllipsisVertical } from 'react-icons/fa6';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { convertYoutubeDuration, displayError, displaySuccess, summaryNumber } from 'Utils';
import { MdMovie } from 'react-icons/md';
import { TbEdit, TbTrash } from 'react-icons/tb';
import { Option } from 'pages/projects/Clusters/BuildingTypes';
import { TheOption } from 'pages/projects/Home/Components/ProjectCard';
import { useClickOutSideComponent } from 'components/projects/Team/Views/Components/OnScreen';
import { LoaderX } from 'components/shared/Loader';
import { TutorialModalWrapper } from './UploadVideoModal';
import ReactPlayer from 'react-player';
import { duration } from 'moment';
import { google } from 'googleapis';

import axios from 'axios';
import { postForm } from 'apis/postForm';
import { removeVideo } from 'store/slices/tutorials';
import useRole from 'Hooks/useRole';

const VideoCard = ({
  coverImage,
  title,
  videoLink,
  views,
  handleEdit,
  _id
}: CategoryGallery & { handleEdit: () => void }) => {
  const [loading, setLoading] = useState(false);
  const { data } = useAppSelector((m) => m.tutorials);
  const [playing, setPlaying] = useState(false);
  const [options, setOptions] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [fetchingStats, setFetchingStats] = useState(false);
  let { isPortfolioManager } = useRole();
  const [stats, setStats] = useState({
    views: '0',
    duration: '00:00'
  });
  const [url, setUrl] = useState<string>();
  const navigate = useNavigate();
  let dispatch = useAppDispatch();
  const optionsRef = useRef<HTMLDivElement>(null);
  let param = useParams();
  useClickOutSideComponent(optionsRef, () => {
    setOptions(false);
  });

  let Category = useMemo(() => {
    return data.find((m) => m._id === param.id);
  }, []);
  useEffect(() => {
    const handleFetch = async () => {
      setLoading(true);
      let _url = await _getFileUrl(coverImage?.key);
      if (_url) {
        setUrl(_url);
      }
      setLoading(false);
    };

    handleFetch();
  }, [coverImage]);
  let x = Math.round(Math.random());

  const deleteVideo = async () => {
    setDeleting(true);
    const { e, response } = await postForm(
      'patch',
      `learning-materials/media/delete?mediaId=${_id}`,
      undefined,
      'iam'
    );
    if (response) {
      displaySuccess('Video removed');
      dispatch(removeVideo({ categoryId: Category?._id, videoId: _id }));
    } else {
      displayError('Could not remove Video');
    }

    setDeleting(false);
  };
  const togglePlayer = () => {
    setPlaying(!playing);
  };

  let getStats = async () => {
    let url = new URL(videoLink);
    let id = url.searchParams.get('v');
    let views = '0';
    let duration = '00:00';
    setFetchingStats(true);
    if (id && videoLink.includes('youtube.com')) {
      try {
        let res = await axios.get(
          `https://www.googleapis.com/youtube/v3/videos?part=snippet,contentDetails,statistics&id=${id}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
        );

        let items = res?.data?.items;

        if (items) {
          let { statistics, contentDetails } = items[0];
          if (statistics?.viewCount) {
            views = statistics.viewCount;
          }
          if (contentDetails?.duration) {
            duration = convertYoutubeDuration(contentDetails.duration);
          }
        }
      } catch (error: any) {
        console.log(error?.message);
      }
    }

    setStats({ duration, views });
    setFetchingStats(false);
  };

  useEffect(() => {
    getStats();
  }, [videoLink]);
  return (
    <>
      {playing && (
        <TutorialModalWrapper closer={togglePlayer}>
          <div className="rounded-md overflow-clip mt-40">
            <ReactPlayer url={videoLink} controls={true} />
          </div>
        </TutorialModalWrapper>
      )}
      <div
        onClick={(e) => {
          console.log(e);
        }}
        className="w-full cursor-pointer ">
        <div
          onClick={togglePlayer}
          className={` w-full relative ${
            loading ? 'animate-pulse_fast' : ''
          } rounded-md bg-ashShade-4 overflow-hidden  py-8 flex items-center justify-center h-40 max-h-40`}>
          {url ? (
            <img
              className="w-full h-40 object-cover "
              onError={() => {
                setUrl(undefined);
              }}
              src={url}
            />
          ) : (
            !loading && <MdMovie size={96} />
          )}
          <span className=" bg-black absolute right-4 bottom-4 px-1 rounded text-xs z-10 text-white ">
            {stats.duration}
          </span>
        </div>
        <div className=" mt-2   w-full flex  justify-between relative">
          <div className=" flex flex-col w-4/5 ">
            <span className=" font-semibold text-bblack-1 text-xl w-full truncate ">{title}</span>
            <span className=" text-ashShade-2 text-sm  ">
              {convertToSentence(Category?.name || '')}
            </span>
            <span className=" text-ashShade-2 text-sm  ">
              {summaryNumber(parseInt(stats.views))} Views
            </span>
          </div>
          <div className="flex flex-col">
            {isPortfolioManager && (
              <div
                onClick={() => {
                  setOptions(!options);
                }}
                className="rounded-full p-2 hover:bg-ashShade-0 ">
                <FaEllipsisVertical color="#9099A8" className="" />
              </div>
            )}

            {options && (
              <div
                ref={optionsRef}
                className="absolute bg-white z-10 right-2 p-2 w-28 rounded-md top-0">
                <TheOption
                  className="text-sm font-semibold "
                  text="Edit"
                  onClick={handleEdit}
                  icon={TbEdit}
                  iconSize={16}
                />
                {deleting ? (
                  <div className="w-full bg-ashShade-0 flex items-cennter justify-center rounded-md p-1">
                    <LoaderX color="red" />
                  </div>
                ) : (
                  <TheOption
                    className=" text-sm  font-semibold text-redShades-2 hover:text-redShades-2 hover:bg-redShades-1 "
                    icon={TbTrash}
                    text="Delete"
                    onClick={deleteVideo}
                    iconHoveredColor="#B63434"
                    iconColor="#B63434"
                    iconSize={16}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoCard;
