import Button from 'components/shared/Button';
import InputField from 'components/shared/InputField';
import { DocSelectBox } from 'components/shared/SelectBox';
import SelectField from 'components/shared/SelectField';
import SuperModal from 'components/shared/SuperModal';
import React, { useRef, useState } from 'react';
import { TbPhotoFilled, TbPlus } from 'react-icons/tb';
import CategoryForm from './CategoryForm';
import UploadForm from './UploadForm';
import { CategoryGallery, TutorialCategoryType } from '../types';

interface Props {
  closer: () => void;
  editing?: CategoryGallery;
}
const UploadVideoModal = ({ closer, editing }: Props) => {
  const [addingCategory, setAddingCategory] = useState(false);
  const toggler = () => {
    setAddingCategory(!addingCategory);
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const handleCancel = () => {
    addingCategory ? toggler() : closer();
  };

  return (
    <SuperModal
      classes=" bg-black bg-opacity-70 flex flex-col items-center "
      closer={addingCategory ? toggler : closer}>
      {addingCategory ? (
        <CategoryForm closer={handleCancel} onSuccess={() => {}} />
      ) : (
        <UploadForm editing={editing} closer={closer} onSuccess={() => {}} onSelectNew={toggler} />
      )}
    </SuperModal>
  );
};

interface WrapperProps {
  closer: () => void;
  children: React.ReactElement;
}
const TutorialModalWrapper = ({ closer, children }: WrapperProps) => {
  return (
    <SuperModal classes=" bg-black bg-opacity-70 flex flex-col items-center " closer={closer}>
      {children}
    </SuperModal>
  );
};

export { TutorialModalWrapper };

export default UploadVideoModal;
