import { FC, useEffect, useState } from 'react';
import { ModalWrapper } from './SubTaskCard';
import { IoIosClose, IoIosArrowBack, IoMdSearch } from 'react-icons/io';
import { flexer, hoverFade } from '../../../../../constants/globalStyles';
import Button from 'components/shared/Button';
import { ProjectTask, SubTask } from '../../types';

interface Props {
  onCloseModal: () => void;
  onDone: (selectedSubTasks: SubTask[]) => void;
  data: ProjectTask[];
  selectedSubTasks: SubTask[];
}

interface CheckboxGroupProps {
  title: string;
  options: SubTask[];
  selectedSubTasks: SubTask[];
  onToggleSubTask: (subTask: SubTask) => void;
}

interface CheckboxLabelProps {
  label: string;
  isChecked: boolean;
  onChange: () => void;
}

const CheckboxLabel: FC<CheckboxLabelProps> = ({ label, isChecked, onChange }) => (
  <label className="flex items-center gap-2">
    <input
      type="checkbox"
      className="border-bash text-bash"
      checked={isChecked}
      onChange={onChange}
    />
    <span className="text-base font-satoshi font-normal text-bash">{label}</span>
  </label>
);

const CheckboxGroup: FC<CheckboxGroupProps> = ({
  title,
  options,
  selectedSubTasks,
  onToggleSubTask
}) => (
  <div className="flex flex-col gap-2">
    <p className="font-satoshi font-medium text-base text-bblack-1">{title}</p>
    <div className="flex flex-col gap-2">
      {options.map((option) => (
        <CheckboxLabel
          key={option._id}
          label={option.name}
          isChecked={selectedSubTasks.some((subTask) => subTask._id === option._id)}
          onChange={() => onToggleSubTask(option)}
        />
      ))}
    </div>
  </div>
);

export const AddPredecessorModal: FC<Props> = ({
  onCloseModal,
  onDone,
  data,
  selectedSubTasks
}) => {
  const [localSelectedSubTasks, setLocalSelectedSubTasks] = useState<SubTask[]>([]);

  useEffect(() => {
    setLocalSelectedSubTasks(selectedSubTasks);
  }, [selectedSubTasks]);

  const handleToggleSubTask = (subTask: SubTask) => {
    setLocalSelectedSubTasks((prev) => {
      if (prev.some((item) => item._id === subTask._id)) {
        return prev.filter((item) => item._id !== subTask._id);
      } else {
        return [...prev, subTask];
      }
    });
  };

  const handleDone = () => {
    onDone(localSelectedSubTasks);
    onCloseModal();
  };

  return (
    <ModalWrapper>
      <div className="flex flex-col gap-6">
        <div className={flexer}>
          <div className="flex items-center gap-4 w-[175.33px]">
            <IoIosArrowBack
              size={16}
              className={`${hoverFade} text-ashShade-2 transform transition-all`}
              onClick={onCloseModal}
            />
            <p className="text-base text-bblack-1 font-satoshi font-bold">Add Predecessor</p>
          </div>
          <IoIosClose
            size={24}
            className={`${hoverFade} text-ashShade-2 transform transition-all`}
            onClick={onCloseModal}
          />
        </div>

        <div className="w-full bg-ashShade-0 py-2 px-4 rounded-lg">
          <div className="flex items-center gap-1">
            <IoMdSearch
              size={20}
              className={`${hoverFade} text-ashShade-2 transform transition-all`}
            />
            <input
              type="text"
              placeholder="Search"
              className="flex-grow text-base text-bash font-medium font-satoshi bg-transparent outline-none"
            />
          </div>
        </div>

        <div className="flex flex-col gap-6 overflow-y-scroll scrollbar-thin h-[300px]">
          {data.map((group) => (
            <CheckboxGroup
              key={group._id}
              title={group.name}
              options={group.subTasks}
              selectedSubTasks={localSelectedSubTasks}
              onToggleSubTask={handleToggleSubTask}
            />
          ))}
        </div>

        <div className="flex items-center justify-end gap-3">
          <Button text="Back" type="secondary" onClick={onCloseModal} />
          <Button text="Done" onClick={handleDone} />
        </div>
      </div>
    </ModalWrapper>
  );
};
