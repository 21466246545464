import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { IoIosClose, IoMdAdd } from 'react-icons/io';
import { ProjectTask, SubTask } from '../../types';
import { flexer, hoverFade } from '../../../../../constants/globalStyles';
import { PMStoreContext } from '../../Context';
import { useParams } from 'react-router-dom';
import { AddPredecessorModal } from './AddPredecessorModal';
import Modal from 'components/shared/Modal';

export type DependecyItem = { label: string; value: ProjectTask };
export type Dependecy = { label: string; value: SubTask };

interface Props {
  onChange: (vl: string[]) => void;
  dependenciesToIgnore: string[];
  disabledOption?: string;
  initialValue?: string[];
}

const SubTaskDependencyPicker = ({
  onChange,
  initialValue,
  disabledOption,
  dependenciesToIgnore
}: Props) => {
  const container = useRef<HTMLDivElement>(null);
  const { powId } = useParams() as { powId: string };

  const { tasks: data } = useContext(PMStoreContext);
  const [dependencies, setDependencies] = useState<SubTask[]>([]);
  const [showAddPredecessorModal, setShowAddPredecessorModal] = useState<boolean>(false);
  const [selectedSubTasks, setSelectedSubTasks] = useState<SubTask[]>([]);

  const initialDependencies = useMemo(() => {
    const init: SubTask[] = [];
    if (initialValue && data[powId]) {
      data[powId].forEach((task) => {
        task.subTasks.forEach((subT) => {
          if (initialValue.includes(subT._id)) {
            init.push(subT);
          }
        });
      });
    }
    return init;
  }, [initialValue, data, powId]);

  useEffect(() => {
    setDependencies(initialDependencies);
    setSelectedSubTasks(initialDependencies);
  }, [initialDependencies]);

  useEffect(() => {
    if (dependencies) {
      onChange(dependencies.map((one) => one._id));
    }
  }, [dependencies, onChange]);

  const activeDependencies = useMemo(() => dependencies.map((one) => one._id), [dependencies]);

  const openAddPredecessorModal = useCallback(() => {
    setShowAddPredecessorModal(true);
  }, []);

  const closeAddPredecessorModal = useCallback(() => {
    setShowAddPredecessorModal(false);
  }, []);

  const handleRemove = useCallback((id: string) => {
    setDependencies((prev) => prev.filter((one) => one._id !== id));
    setSelectedSubTasks((prev) => prev.filter((one) => one._id !== id));
  }, []);

  const handleAdd = useCallback((selectedSubTasks: SubTask[]) => {
    setDependencies((prev) => {
      const existingIds = new Set(prev.map((dep) => dep._id));
      const newDependencies = selectedSubTasks.filter((subTask) => !existingIds.has(subTask._id));
      return [...prev, ...newDependencies];
    });
    setSelectedSubTasks((prev) => [...prev, ...selectedSubTasks]);
  }, []);

  const sortedDependencies = useMemo(() => {
    return [...dependencies].sort(
      (a, b) => new Date(b.endDate).getTime() - new Date(a.endDate).getTime()
    );
  }, [dependencies]);

  return (
    <div className="w-full picker flex my-3 relative flex-col " ref={container}>
      <label className="font-Medium text-bash text-sm">Predecessor</label>
      <div
        className={
          flexer +
          `p-2 picker relative overflow-hidden cursor-pointer border border-bash rounded-md mt-1`
        }>
        <div className="flex-1 picker flex flex-col gap-2 items-center overflow-x-scroll">
          <div
            className={
              hoverFade +
              `flex justify-center items-center gap-2 bg-ashShade-0 p-2 w-full rounded-md picker cursor-pointer`
            }
            onClick={openAddPredecessorModal}>
            <button
              className="text-blueShades-3 picker font-medium cursor-pointer font-satoshi text-base"
              onClick={openAddPredecessorModal}>
              Add predecessor
            </button>
            <IoMdAdd
              size={16}
              onClick={openAddPredecessorModal}
              className="text-blueShades-3 picker transform transition-all"
            />
          </div>
          <div className="flex flex-col gap-2 w-full">
            {sortedDependencies.map((dep) => (
              <div
                key={dep._id}
                className="flex justify-between gap-2 bg-ashShade-0 py-2 px-4 w-full rounded-md picker cursor-pointer">
                <div>
                  <p className="font-satoshi font-medium text-base text-bblack-1">{dep.name}</p>
                  <p className="font-satoshi font-medium text-sm text-bash">
                    Ends: <span>{new Date(dep.endDate).toLocaleDateString('en-GB')}</span>
                  </p>
                </div>
                <div className="flex justify-center items-center w-[14px] h-[14px] bg-ashShade-1 rounded-full">
                  <IoIosClose
                    size={12}
                    onClick={() => handleRemove(dep._id)}
                    className="text-white picker transform transition-all"
                  />
                </div>
              </div>
            ))}
          </div>
          <Modal
            visible={showAddPredecessorModal}
            toggle={() => {}}
            overlayClassName="opacity-50 backdrop-blur-0"
            className="backdrop-blur-0 drop-shadow-lg">
            <AddPredecessorModal
              onCloseModal={closeAddPredecessorModal}
              data={data[powId]}
              onDone={handleAdd}
              selectedSubTasks={selectedSubTasks}
            />
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default SubTaskDependencyPicker;
