import { uploadFile } from 'Hooks/useProjectImages';
import { displayError } from 'Utils';
import { postForm } from 'apis/postForm';
import Button from 'components/shared/Button';
import InputField from 'components/shared/InputField';
import { DocSelectBox } from 'components/shared/SelectBox';
import { StoreContext } from 'context';
import { useFormik } from 'formik';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateCategories } from 'store/slices/tutorials';
import * as yup from 'yup';
import { CategoryGallery } from '../types';
interface Props {
  closer: () => void;
  onSuccess: () => void;
  isEditing?: false;
  editing?: CategoryGallery;
  onSelectNew?: () => void;
}

const UploadForm = ({ closer, onSuccess, isEditing, editing, onSelectNew }: Props) => {
  let dispatch = useAppDispatch();
  const { data: allData } = useContext(StoreContext);
  const { data, loading } = useAppSelector((m) => m.tutorials);
  const [creating, setCreating] = useState(false);
  const [file, setFile] = useState<File>();
  let params = useParams();
  let { values, touched, setFieldValue, handleSubmit, handleChange } = useFormik({
    initialValues: editing
      ? { title: editing.title, categoryId: params.id, videoLink: editing.videoLink }
      : {
          title: '',
          categoryId: params.id,
          videoLink: ''
        },
    onSubmit: async (data) => {
      submit(data);
    },
    validationSchema: yup.object({
      title: yup.string().required().min(1, 'Please enter a valid title'),
      categoryId: yup.string().required(),
      videoLink: yup
        .string()
        .matches(
          /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
          'Please enter a valid url'
        )
        .required()
    })
  });

  const categoryOptions = useMemo(() => {
    return data.map((m) => ({ value: m._id, label: m.name }));
  }, [data]);

  const submit = async (data: typeof values) => {
    setCreating(true);
    let finalData: { [key: string]: any } = { ...data };

    // let fileObject: any;
    if (file) {
      const proj = allData[0]?._id;
      let { e, response, file: _file } = await uploadFile(file, proj, 'tutorials');
      if (response) {
        let fileObject = response.data.data;
        if (fileObject) {
          let coverImage = {
            key: fileObject?.S3Key,
            meta: {
              name: fileObject?.alias,
              type: fileObject.ContentType
            }
          };
          finalData['coverImage'] = coverImage;
        }
      }
    }
    if (editing) {
      if (!file) finalData['coverImage'] = editing?.coverImage;
      finalData['mediaId'] = editing?._id;
      delete finalData?.categoryId;
    }

    let { e, response } = await postForm(
      editing ? 'patch' : 'post',
      `learning-materials/${editing ? 'update' : 'add'}`,
      finalData,
      'iam'
    );
    if (response) {
      dispatch(updateCategories(response.data.data));
      closer();
    } else {
      displayError('Could not upload video');
    }
    setCreating(false);
  };


  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className=" bg-white  rounded-md p-6 mt-24 w-4/5 sm:w-3/5 lg:w-1/2 xl:w-1/3 ">
      <div className=" flex mb-6 items-center justify-between">
        <p className=" text-xl font-semibold ">{editing ? 'Edit' : 'Upload'} Video</p>

        <span onClick={closer} className=" hover:cursor-pointer text-bash ">
          Close
        </span>
      </div>

      <InputField
        value={values.title}
        name="title"
        onChange={handleChange}
        label="Title"
        placeholder="Video Title"
      />

      <InputField
        name="title"
        onChange={(e) => {
          setFile(e.target.files ? e.target.files[0] : undefined);
        }}
        type="file"
        label="Cover Image"
        placeholder="Video Title"
        error={editing ? editing?.coverImage.key : ''}
      />
      <div className="mt-4">
        <p className="text-sm mt-4 ">Select Category</p>
        <div className="mt-1.5">
          <DocSelectBox
            onSelectNew={() => {
              if (onSelectNew) {
                onSelectNew();
              }
            }}
            placeholder="Select Category"
            options={[...categoryOptions, { label: 'New Folders', value: 'other_+=' }]}
            state={values.categoryId || ''}
            setter={(v: string) => {
              setFieldValue('categoryId', v);
            }}
            newBtnText="Create Category"
          />
        </div>
      </div>
      <InputField
        value={values.videoLink}
        name="videoLink"
        onChange={handleChange}
        label="Video Link"
        placeholder="Paste Video Link"
      />

      <div className=" flex mt-6 items-center justify-end gap-x-3">
        <Button onClick={closer} type="secondary" text="Cancel" />
        <Button
          isLoading={creating}
          onClick={() => handleSubmit()}
          type="primary"
          text={editing ? 'Save Changes' : 'Upload'}
        />
      </div>
    </div>
  );
};

export default UploadForm;
