import React, { useEffect, useMemo, useState } from 'react';
import Layout from '../Home/Layout';
import { Outlet, useNavigate } from 'react-router-dom';
import { FaArrowLeftLong, FaPlus } from 'react-icons/fa6';
import { TbSearch } from 'react-icons/tb';
import Button from 'components/shared/Button';
import { list } from './constants';
import GroupCard from './components/GroupCard';
import BackButton from './components/BackButton';
import { postForm } from 'apis/postForm';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { loadCategories } from 'store/slices/tutorials';
import GhostBids, { GhostCard } from 'components/projects/bids/projectowner/bid/GhostBids';
import NoContent from 'components/projects/photos/NoContent';
import UploadVideoModal, { TutorialModalWrapper } from './components/UploadVideoModal';
import { ModalWrapper } from 'components/projects/management/POW/Components/SubTaskCard';
import CategoryForm from './components/CategoryForm';
import { TutorialCategoryType } from './types';
import useRole from 'Hooks/useRole';

const CategoryList = () => {
  const [showModal, setShowModal] = useState(false);
  const { data, loading } = useAppSelector((m) => m.tutorials);
  const [editing, setEditing] = useState<TutorialCategoryType>();
  const [search, setSearch] = useState('');
  const { isPortfolioManager } = useRole();
  const closer = () => {
    setShowModal(!showModal);
  };

  const handleEdit = (val: TutorialCategoryType) => () => {
    setEditing(val);
    setShowModal(!showModal);
  };

  const filtered = useMemo(() => {
    return data.filter((m) => m.name.toLowerCase().includes(search.toLowerCase())) || [];
  }, [data, search]);

  let noContent = <NoContent title=" No Video Categories Created yet" subtitle="  " />;

  return (
    <div className="w-full ">
      {showModal && (
        <TutorialModalWrapper closer={closer}>
          <CategoryForm
            closer={closer}
            value={editing}
            isEditing={Boolean(editing)}
            onSuccess={closer}
          />
        </TutorialModalWrapper>
      )}
      <BackButton />
      <div className=" mt-6 flex flex-col md:flex-row  md:items-center justify-between  ">
        <p className=" text-3xl font-bold  ">Tutorials</p>

        <div className=" flex items-center gap-x-4  ">
          <div className=" bg-white rounded-md flex border border-ashShade-2 items-center p-2">
            <TbSearch className=" mr-2" color="#9099A8" />
            <input
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              placeholder="Search"
              className=" outline-none "
            />
          </div>
          {isPortfolioManager && (
            <Button
              onClick={() => setShowModal(true)}
              type="primary"
              className="  "
              text="Create Category"
              LeftIcon={<FaPlus className="mr-2" />}
            />
          )}
        </div>
      </div>

      {loading || data.length > 0 ? (
        <div
          className={`  w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-6 gap-x-5 gap-y-6 `}>
          {loading ? (
            Array(4)
              .fill('ff')
              .map((m) => <GhostCard />)
          ) : data.length > 0 ? (
            filtered.length > 0 ? (
              filtered.map((m) => <GroupCard key={m._id} {...m} handleEdit={handleEdit(m)} />)
            ) : (
              <div>No Search results for &quot;{search}&quot;</div>
            )
          ) : (
            noContent
          )}
        </div>
      ) : (
        noContent
      )}
    </div>
  );
};

export default CategoryList;
