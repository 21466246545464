import React from 'react';
import Layout from '../Home/Layout';
import { Outlet, useNavigate } from 'react-router-dom';
import { FaArrowLeftLong, FaPlus } from 'react-icons/fa6';
import { TbSearch } from 'react-icons/tb';
import Button from 'components/shared/Button';
import { list } from './constants';
import GroupCard from './components/GroupCard';
import useTutorials from 'Hooks/useTutorials';

const Index = () => {
  useTutorials()
  return (
    <Layout path="tutorials">
      <Outlet />
    </Layout>
  );
};

export default Index;
