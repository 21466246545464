import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { TutorialCategoryType } from 'pages/projects/tutorials/types';

let initialState: {
  loading?: boolean;
  data: TutorialCategoryType[];
  current?: TutorialCategoryType;
} = {
  loading: false,
  data: []
};

export const tutorialSlice = createSlice({
  name: 'tutorial',
  initialState,
  reducers: {
    loadCategories: (state, action) => {
      let newState = state;
      for (let x in action.payload) {
        newState[x as keyof typeof initialState] = action.payload[x];
      }
      return newState;
    },
    updateCategories: (state, action) => {
      let newState = state;
      for (let x = 0; x < state.data.length; x++) {
        if (action.payload._id === newState.data[x]._id) {
          newState.data[x] = action.payload;
          break;
        }
      }
      return newState;
    },
    removeCategories: (state, action) => {
      let newState = state;
      newState.data = newState.data.filter((m) => m._id !== action.payload);
      return newState;
    },
    addCategories: (state, action) => {
      let newState = state;
      newState.data.push(action.payload);
      return newState;
    },

    removeVideo: (state, action) => {
      let newState = state;
      let category = newState.data.find((m) => m._id === action.payload.categoryId);

      if (category) {
        category.gallery = category.gallery.filter((m) => m._id !== action.payload.videoId);

        newState.data = newState.data.map((m) => {
          if (category?._id === m._id) {
            return category;
          }
          return m;
        });
      }
    }
  }
});

export const { loadCategories, updateCategories, removeCategories, addCategories, removeVideo } =
  tutorialSlice.actions;

export const tutorials = (state: RootState) => state.tutorials;
export default tutorialSlice.reducer;
